const main_menu = [
  {
    id: 1,
    name: "Home",
    path: "/",
  },
  // {
  //   id: 2,
  //   name: "The Club",
  //   path: "/the-club",
  // },
  {
    id: 3,
    name: "Pricing",
    path: "/pricing",
  },
  {
    id: 4,
    name: "Events",
    path: "/events",
  },
  {
    id: 5,
    name: "Books",
    path: "/books",
  },
  {
    id: 6,
    name: "Community",
    path: "https://community.engineeringbookclub.com",
  },
  // {
  //   id: 7,
  //   name: "Join Now",
  //   path: "https://community.engineeringbookclub.com/join?invitation_token=5301a87965bcb756cad1e4489d2164b8c66be003-be333707-49c1-4dbe-a0a5-e7c8aa0dc325",
  // },
];

export {main_menu};
