import Link from "next/link";
import {footerMenuList, socialIcons} from "../data/footer_data";
import {site_data} from "../data/site_data";

const footer = () => {
  return (
    <>
      {/* <!-- Footer --> */}

      <footer className="dark:bg-jacarta-900 page-footer bg-white">
        <div className="container">
          <div className="grid grid-cols-6 gap-x-7 gap-y-14 pt-24 pb-12 md:grid-cols-12">
            <div className="col-span-3 md:col-span-4">
              {/* <!-- Logo --> */}
              <Link href="#">
                <a className="mb-6 inline-block">
                  <img
                    src="/images/logo.png"
                    className="max-h-7 dark:hidden"
                    alt={site_data.title}
                  />
                </a>
              </Link>

              <Link href="#">
                <a className=" mb-6 inline-block">
                  <img
                    src="/images/logo_white.png"
                    className="hidden max-h-7 dark:block mb-6"
                    alt={site_data.title}
                  />
                </a>
              </Link>
              <p className="dark:text-jacarta-60 mb-12">
                Join us in the Engineering Book Club community to read and discuss the latest engineering books with other professionals
              </p>

              {/* <!-- Socials --> */}
              <div className="flex space-x-5">
                {socialIcons.map((item) => {
                  const {id, href, text} = item;
                  return (
                    <Link href={href} key={id}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="group cursor-pointer"
                      >
                        <svg className="icon group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white">
                          <use xlinkHref={`/icons.svg#icon-${text}`}></use>
                        </svg>
                      </a>
                    </Link>
                  );
                })}
              </div>
            </div>

            <div className="col-span-full sm:col-span-1 md:col-span-4"></div>

            {footerMenuList.map((single) => (
              <div
                className={`col-span-full sm:col-span-3 text-right md:col-span-2 ${single.diffClass}`}
                key={single.id}
              >
                <p className="font-display text-jacarta-700 mb-6 text-sm dark:text-white">
                  {single.title}
                </p>
                <ul className="dark:text-jacarta-60 flex flex-col space-y-1">
                  {single.list.map((item) => {
                    const {id, href, text} = item;
                    return (
                      <li key={id}>
                        <Link href={href}>
                          <a className="hover:text-accent dark:hover:text-white">
                            {text}
                          </a>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ))}
          </div>

          <div className="flex flex-col items-center justify-between space-y-2 py-8 sm:flex-row sm:space-y-0">
            <span className="dark:text-jacarta-60 text-sm">
              <span>© {new Date().getFullYear()} - {site_data.title}</span>
            </span>

            <ul className="dark:text-jacarta-60 flex flex-wrap space-x-4 text-sm">
              <li>
                <Link href="/terms-and-conditions">
                  <a className="hover:text-accent dark:hover:text-white">
                    Terms & Conditions
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/privacy">
                  <a className="hover:text-accent dark:hover:text-white">
                    Privacy
                  </a>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default footer;
