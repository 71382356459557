const footerMenuList = [
  {
    id: 2,
    title: "About Us",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "/the-club",
        text: "The Club",
      },
      {
        id: 2,
        href: "/community-guidelines",
        text: "Community Guidelines",
      },
      {
        id: 3,
        href: "/code-of-conduct",
        text: "Code Of Conduct",
      },
    ],
  },
  {
    id: 3,
    title: "More",
    diffClass: "",
    list: [
      {
        id: 2,
        href: "/podcast",
        text: "Podcast",
      },
      {
        id: 3,
        href: "/resources",
        text: "Resources",
      },
      {
        id: 4,
        href: "/newsletter",
        text: "Newsletter",
      },
      {
        id: 5,
        href: "/paths-2025",
        text: "Paths 2025",
      }
    ],
  },
];

const socialIcons = [
  {
    id: 1,
    href: "https://x.com/TheEngBookClub",
    text: "twitter",
  },
  {
    id: 2,
    href: "https://www.linkedin.com/company/engineering-book-club",
    text: "world",
  },
];

export {footerMenuList, socialIcons};
